<div class="features-boxed mb-5 timeoverview">
  <div class="form-container">
    <div class="image-holder"></div>
    <form method="post" class="container">
      <div class="intro small">
        <h3 class="title">Auswertung</h3>
      </div>
      <div class="intro large">
        <h3 class="text-center title">Auswertung</h3>
      </div>
      <button
        class="btn btn-custom mb-3 d-block px-3 css-btn-time-evaluation"
        [routerLink]="['/selfresults']"
      >
        <span>Zeitauswertung</span>
        <i class="fa fa-chevron-right ml-3"></i>
      </button>
      <h4 class="ml-2">Aufträge</h4>
      <div class="input-group mb-2">
        <div class="input-group-append">
          <span class="input-group-text" id="entryFilterTitle"
            ><i class="fas fa-filter"></i>&nbsp;Filter:</span
          >
        </div>
        <input
          type="search"
          id="entryfilter"
          [(ngModel)]="orderDescription"
          [ngModelOptions]="{ standalone: true }"
          class="form-control"
          placeholder="Auftrag oder Kunde eingeben..."
          (keydown.enter)="onEnterKeyPressed($event)"
        />
      </div>
      <div class="custom-select-box">
        <select
          appSortParams
          (param)="setSortParams($event)"
          class="form-control"
        >
          <optgroup label="Sortieren nach...">
            <option value="orderItemNoAsc" selected>{{ "screens.orderItemOverviewHistory.auftragsnr" | translate }} aufsteigend</option>
            <option value="orderItemNoDesc">{{ "screens.orderItemOverviewHistory.auftragsnr" | translate }} absteigend</option>
            <option value="plannedDateAsc">Termin aufsteigend</option>
            <option value="plannedDateDesc">Termin absteigend</option>
            <option value="customerAsc">Kunde aufsteigend</option>
            <option value="customerDesc">Kunde absteigend</option>
          </optgroup>
        </select>
        <i class="fas fa-caret-down custom-select-box-icon"></i>
      </div>

      <div class="orderItemsOverview-container">
        <div class="orderItemOverview-item">
          <div>Kunde</div>
          <div>{{ "screens.orderItemOverviewHistory.auftragsnr" | translate }}</div>
          <div>{{ "screens.orderItemOverviewHistory.machine" | translate }}</div>
          <div>{{ "screens.orderItemOverviewHistory.plannedTS" | translate }}</div>
          <div>{{ "screens.orderItemOverviewHistory.endTS" | translate }}</div>
          <div>Arbeitszeit</div>
          <div>Status</div>
          <div>Aktionen</div>
        </div>
        <div
          class="orderItemOverview-item"
          *ngFor="
            let orderItem of orderItems
              | filter: orderDescription
              | sort: direction:column:type
          "
          [ngClass]="{ 'active': orderItem.bearbeitungszustand < 400 }"
        >
          <div>{{ orderItem.kunde }} <i *ngIf="orderItem.noUserTime == true" class="fa fa-user-slash css-orderitem-no-user-time-icon"></i></div>
          <div>{{ orderItem.auftragsnr }}</div>
          <div>{{ orderItem.machineName }}</div>
          <div>{{ orderItem.geplanterTermin | date: "dd.MM.YYYY" }}</div>
          <div>{{ orderItem.endTS | date: "dd.MM.YYYY" }}</div>
          <div>{{ orderItem.arbeitszeit }}h</div>
          <div>
            <span *ngIf="orderItem.bearbeitungszustand < 400">offen</span>
            <span *ngIf="orderItem.bearbeitungszustand >= 400"
              >abgeschlossen</span
            >
          </div>
          <div>
            <button
              class="btn btn-custom btn-outline resultBtn"
              [routerLink]="[
                '/detailOrderitem',
                orderItem.id,
                orderItem.auftragsnr
              ]"
              *ngIf="isAdmin"
            >
              <i class="fas fa-info"></i>
            </button>
            <button
              class="btn btn-custom btn-outline resultBtn"
              [routerLink]="[
                '/editOrderitem',
                orderItem.id,
                orderItem.auftragsnr
              ]"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button
              class="btn btn-custom btn-outline resultBtn"
              (click)="removeOrderItem(orderItem)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </div>
        </div>
        <div *ngIf="resultsloading" class="orderItemOverview-loadingspinner pb-3 pt-3">
          <div class="spinner-border spinner-border-sm"></div>
        </div>
        <div
          *ngIf="orderItems.length == 0 && !resultsloading"
          class="p-3 text-center"
        >
          <i class="fa fa-clock-o mr-1"></i>
          Noch keine Aufträge vorhanden.
        </div>
      </div>
    </form>
  </div>
</div>
