import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleChartsModule } from 'angular-google-charts';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackButtonComponent } from './components/buttons/back-button/back-button.component';
import { ButtonComponent } from './components/buttons/button/button.component';
import { NavigateButtonComponent } from './components/buttons/navigate-button/navigate-button.component';
import { OrderitemCardComponent } from './components/orderitem-card/orderitem-card.component';
import { PasswordMatchDirective } from './directives/password-match.directive';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { FilterPipe } from './helpers/filter.pipe';
import { OrderitemFilterPipe } from './helpers/orderitem-filter.pipe';
import { SortParamsDirective } from './helpers/sort-params.directive';
import { SortPipe } from './helpers/sort.pipe';
import { ModalComponent } from './modal/modal.component';
import { OrderItemRemarksModalComponent } from './modal/orderitem-remarks-modal/orderitem-remarks-modal-component';
// import { AddOrderComponent } from './screens/add-order/add-order.component';
import { CorrectionComponent } from './screens/correction/correction.component';
import { LoginComponent } from './screens/login/login.component';
import { MultiplierComponent } from './screens/multiplier/multiplier.component';
import { OrderItemDetailComponent } from './screens/order-item-detail/order-item-detail.component';
import { OrderItemHistoryOverviewComponent } from './screens/order-item-history-overview/order-item-history-overview.component';
import { OrderItemOverviewComponent } from './screens/order-item-overview/order-item-overview.component';
import { PwResetComponent } from './screens/pw-reset/pw-reset.component';
import { SettingsComponent } from './screens/settings/settings.component';
import { TimeTrackingEvaluationComponent } from './screens/time-tracking-evaluation/time-tracking-evaluation.component';
import { UserAddComponent } from './screens/user-add/user-add.component';
import { UserOverviewComponent } from './screens/user-overview/user-overview.component';
import { SocketioService } from './services/socketio.service';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { LineBreakCutoffDirective } from './directives/line-break-cutoff.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewComponent } from './screens/pdf-view/pdf-view.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        OrderItemOverviewComponent,
        LoginComponent,
        // AddOrderComponent,
        SettingsComponent,
        OrderItemHistoryOverviewComponent,
        MultiplierComponent,
        SortParamsDirective,
        SortPipe,
        CorrectionComponent,
        OrderItemDetailComponent,
        FilterPipe,
        TimeTrackingEvaluationComponent,
        OrderitemFilterPipe,
        SplashScreenComponent,
        ModalComponent,
        OrderItemRemarksModalComponent,
        UserOverviewComponent,
        UserAddComponent,
        PwResetComponent,
        PasswordMatchDirective,
        ButtonComponent,
        BackButtonComponent,
        NavigateButtonComponent,
        OrderitemCardComponent,
        LineBreakCutoffDirective,
        PdfViewComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        GoogleChartsModule,
        PdfViewerModule,
        MatDialogModule,
        HammerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        SocketioService,
        authInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

